import React from 'react';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import $ from 'jquery';
import { Authenticated } from 'common';
import { DocumentTitle, PromptWrapper } from '../../../ui';

import PrintOptionsContainer from '../PrintOptionsContainer';
import TemporaryPermissions from '../TemporaryPermissions';
import AutomatedEmail from '../AutomatedEmail';
import SamlConfig from '../SamlConfig';
import CaseNotificationsContainer from '../CaseNotificationsContainer';
import CaseNotificationsStore from '../../stores/CaseNotificationsStore';
import PrintSettingsStore from '../../../stores/PrintSettingsStore';
import CaseIntegrationContainer from '../CaseIntegrationContainer';
import UserAccountLimitContainer from '../UserAccountLimitContainer';
import NewAppExperienceContainer from '../NewAppExperienceContainer';
import PinnedTimezoneContainer from '../PinnedTimezoneContainer';

const GeneralSettingsView = observer(
  class GeneralSettingsView extends React.Component {
    // Observables
    tempSettingsDirty = false;
    autoEmailSettingsDirty = false;
    samlConfigSettingsDirty = false;
    userAccountLimitStoreDirty = false;

    componentDidMount() {
      CaseNotificationsStore.refresh();
    }

    componentWillUnmount() {
      CaseNotificationsStore.reset();
    }

    // Computed
    get anySettingsDirty() {
      return (
        this.tempSettingsDirty ||
        CaseNotificationsStore.isDirty ||
        PrintSettingsStore.isDirty ||
        this.autoEmailSettingsDirty ||
        this.samlConfigSettingsDirty ||
        this.userAccountLimitStoreDirty
      );
    }

    scroll(title) {
      const scrollAmount = document.getElementById(title).offsetTop - 60;
      $('.generalSettings_view__body').animate(
        {
          scrollTop: scrollAmount,
        },
        300
      );
    }

    render() {
      return (
        <div className="generalSettings__view">
          <DocumentTitle text="General Settings" />
          <aside>
            <h1> General Settings </h1>
            <ul>
              <Authenticated permission="ACCESS_AUTH_CREATE">
                <h2 onClick={() => this.scroll('settings__wrapper-Temporary')}>
                  Temporary Permissions
                </h2>
              </Authenticated>
              <Authenticated permission="GENERAL_SETTINGS_MODIFY">
                <h2
                  onClick={() =>
                    this.scroll('settings__wrapper-Case_Notifications')
                  }
                >
                  Case Age Notifications
                </h2>
              </Authenticated>
              <h2 onClick={() => this.scroll('settings__wrapper-Print')}>
                Print Options
              </h2>
              <Authenticated appRole="ADMIN">
                <h2
                  onClick={() => this.scroll('settings__wrapper-Site_Banner')}
                >
                  Site Banner
                </h2>
              </Authenticated>
              <Authenticated appRole="ADMIN">
                <h2 onClick={() => this.scroll('settings__wrapper-Auto_Email')}>
                  Automated Email
                </h2>
              </Authenticated>
              <Authenticated appRole="ADMIN">
                <h2
                  onClick={() => this.scroll('settings__wrapper-SAML_Config')}
                >
                  SAML Configuration
                </h2>
              </Authenticated>
              <Authenticated appRole="ADMIN">
                <h2
                  onClick={() => this.scroll('case_integration')}
                  data-cy="integration-section-link"
                >
                  Case Integration
                </h2>
              </Authenticated>
              <Authenticated appRole="ADMIN">
                <h2
                  onClick={() =>
                    this.scroll('settings__wrapper-User_Account_Limit')
                  }
                >
                  Customer User Accounts
                </h2>
              </Authenticated>
              <Authenticated appRole="ADMIN">
                <h2
                  onClick={() =>
                    this.scroll('settings__wrapper-New_Application_Experience')
                  }
                >
                  New Application Experience
                </h2>
              </Authenticated>
              <Authenticated permission="GENERAL_SETTINGS_MODIFY">
                <h2
                  onClick={() =>
                    this.scroll('settings__wrapper-PinnedTimezone')
                  }
                >
                  Timezone
                </h2>
              </Authenticated>
            </ul>
          </aside>
          <section className="generalSettings_view__body">
            <Authenticated permission="ACCESS_AUTH_CREATE">
              <TemporaryPermissions
                onDirtyChange={dirty => (this.tempSettingsDirty = dirty)}
              />
            </Authenticated>
            <Authenticated permission="GENERAL_SETTINGS_MODIFY">
              <CaseNotificationsContainer />
            </Authenticated>
            <PrintOptionsContainer />
            <Authenticated appRole="ADMIN">
              <AutomatedEmail
                onDirtyChange={dirty => (this.autoEmailSettingsDirty = dirty)}
              />
            </Authenticated>
            <Authenticated appRole="ADMIN">
              <SamlConfig
                onDirtyChange={dirty => (this.samlConfigSettingsDirty = dirty)}
              />
            </Authenticated>
            <Authenticated appRole="ADMIN">
              <CaseIntegrationContainer />
            </Authenticated>
            <Authenticated appRole="ADMIN">
              <UserAccountLimitContainer
                onDirtyChange={dirty =>
                  (this.userAccountLimitStoreDirty = dirty)
                }
              />
            </Authenticated>
            <Authenticated appRole="ADMIN">
              <NewAppExperienceContainer />
            </Authenticated>
            <Authenticated permission="GENERAL_SETTINGS_MODIFY">
              <PinnedTimezoneContainer />
            </Authenticated>
          </section>
          <PromptWrapper when={this.anySettingsDirty} />
        </div>
      );
    }
  }
);

decorate(GeneralSettingsView, {
  tempSettingsDirty: observable,
  autoEmailSettingsDirty: observable,
  samlConfigSettingsDirty: observable,
  userAccountLimitStoreDirty: observable,
  anySettingsDirty: computed,
});

GeneralSettingsView.displayName = 'GeneralSettingsView';

export default GeneralSettingsView;
