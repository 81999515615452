import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
  toJS,
} from 'mobx';
import moment from 'moment-timezone';
import $ from 'jquery';

import {
  AccessClient,
  DateHelpers,
  MedicationAdministrationClient,
  MedicationDiscrepancyClient,
  MedicationHandlingClient,
  MedicationOrderClient,
  PatientClient,
  TimeEntryClient,
  UserClient,
  VipClient,
  DownloadUtils,
  HalUtils,
  RouterContainer,
  EmployeeCasesStore,
  PermissionStore,
  SingletonStore,
} from 'common';
import RelationshipStore from '../../../stores/RelationshipStore';
import EmployeeAuthorizationsStore from '../../../stores/EmployeeAuthorizationsStore';
import PatientAuthorizationsStore from '../../../stores/PatientAuthorizationsStore';
import PatientCasesStore from '../../../stores/PatientCasesStore';
import PinnedTimezoneStore from '../../../stores/PinnedTimezoneStore';
import {
  sqSubFilter1,
  sqSubFilter2,
} from '../../options/EventSubFilter/constants';

const checkName = val => val || '(Unknown)';

class UserStore extends SingletonStore {
  constructor() {
    super();
    reaction(
      () => this.userId,
      () => this.refresh()
    );

    reaction(
      () => this.userId,
      userId => {
        if (userId) {
          EmployeeCasesStore.fetch(userId);
        }
      }
    );
  }

  // Observables
  userId = null;

  fetch() {
    if (this.userId) return UserClient.getById(this.userId);
  }
}

decorate(UserStore, {
  userId: observable,
});

const userStore = new UserStore();

class PatientStore extends SingletonStore {
  constructor() {
    super();
    reaction(
      () => this.patientId,
      () => this.refresh()
    );

    reaction(
      () => this.patientId,
      patientId => {
        if (patientId) {
          PatientCasesStore.fetch(patientId);
        }
      }
    );
  }

  // Observables
  patientId = null;

  fetch() {
    if (this.patientId) return PatientClient.getById(this.patientId);
  }
}

decorate(PatientStore, {
  patientId: observable,
});

const patientStore = new PatientStore();

class VipStore extends SingletonStore {
  constructor() {
    super();
    reaction(
      () => [this.patientId],
      () => this.refresh()
    );
  }

  // Observable
  patientId = null;

  fetch() {
    if (this.patientId) return VipClient.findByPatient(this.patientId);
  }
}

decorate(VipStore, {
  patientId: observable,
});

const vipStore = new VipStore();

const TAB_ASSESSMENTS = 'Assessments';
const TAB_EVENTS = 'Events';
const TAB_INCIDENTS = 'Incidents';

class Store {
  constructor() {
    this.init();
  }
  // Observables
  accessesVisible;
  administrationsVisible;
  activeDrawerTab = 'Events';
  discrepanciesVisible;
  employeePg1Visible = true;
  fromDate = moment()
    .subtract(1, 'months')
    .startOf('day');
  handlingsVisible;
  optionsVisible = false;
  ordersVisible;
  timeEntriesVisible;
  params = {};
  patientLabel = 'name';
  patientPg1Visible = true;
  sort = 'startTime,asc';
  subFilter1;
  subFilter2;
  toDate = moment().startOf('day');
  uoaMergedPreference = false;
  userLabel = 'name';

  // NOT observable
  maxAccesses = AccessClient.maxAccesses;

  init() {
    autorun(() => {
      userStore.userId = this.userIdParam;
      RelationshipStore.fetchUser(userStore.userId);
    });

    autorun(() => {
      patientStore.patientId = this.patientIdParam;
      RelationshipStore.fetchPatient(patientStore.patientId);
    });

    autorun(() => {
      vipStore.patientId = this.patientIdParam;
    });

    autorun(() => {
      if (this.isUserFocus && this.activeUsers) {
        this.activeUsers
          .split(',')
          .forEach(u => EmployeeAuthorizationsStore.fetch(u));
      } else if (this.isPatientFocus && this.activePatients) {
        this.activePatients
          .split(',')
          .forEach(p => PatientAuthorizationsStore.fetch(p));
      }
    });

    autorun(() => {
      [
        'accessesVisible',
        'administrationsVisible',
        'discrepanciesVisible',
        'handlingsVisible',
        'ordersVisible',
        'timeEntriesVisible',
      ].forEach(evt => {
        if (
          !localStorage.getItem(`activity-${evt}`) ||
          this.filterOptions.length === 1
        ) {
          localStorage.setItem(`activity-${evt}`, true);
        }
        this[evt] = localStorage.getItem(`activity-${evt}`) === 'true';
      });
    });

    autorun(() => {
      const userLabel = RouterContainer.query.userLabel;
      this.userLabel = userLabel || 'name';
    });

    autorun(() => {
      const patientLabel = RouterContainer.query.patientLabel;
      this.patientLabel = patientLabel || 'name';
    });

    autorun(() => {
      const { subFilter1, subFilter2 } = RouterContainer.query;

      this.subFilter1 = sqSubFilter1.get(subFilter1);
      this.subFilter2 = sqSubFilter2.get(subFilter2);

      if (subFilter1 || subFilter2) this.setOptionsVisible(true);
    });

    autorun(() => {
      const sort = RouterContainer.query.sort;

      // can only sort opposing entity
      if (
        !sort ||
        (this.isPatientSort && this.isPatientFocus) ||
        (this.isUserSort && this.isUserFocus)
      ) {
        this.sort = 'startTime,asc';
      } else {
        this.sort = sort;
      }
    });
  }

  clientMap = {
    Accesses: AccessClient,
    Administrations: MedicationAdministrationClient,
    Discrepancies: MedicationDiscrepancyClient,
    Handlings: MedicationHandlingClient,
    Orders: MedicationOrderClient,
    'Time Entries': TimeEntryClient,
  };

  // Action
  toggleMergedUOA() {
    this.uoaMergedPreference = !this.uoaMergedPreference;
  }

  // Computed
  get loading() {
    return userStore.loading || patientStore.loading;
  }

  // Computed
  get includeUOA() {
    return this.uoaMergedPreference && this.isUserFocus;
  }

  // Computed
  get timezone() {
    return PinnedTimezoneStore.storedTimezone;
  }

  // Computed
  get activeLabel() {
    if (this.isUserFocus) {
      return this.patientLabel;
    }

    return this.userLabel;
  }

  // Computed
  get user() {
    return userStore.result;
  }

  // Computed
  get userTags() {
    return this.user && this.user.tags;
  }

  // Computed
  get patient() {
    return patientStore.result;
  }

  // Computed
  get vipRegistration() {
    const regs = vipStore.result;
    return HalUtils.getData(regs);
  }

  // Computed
  get userIdParam() {
    return this.params.employeeId;
  }

  // Computed
  get userId() {
    const user = userStore.result;
    const eid = this.params.employeeId;
    if (user && eid) return HalUtils.getId(userStore.result);
    return null;
  }

  // Computed
  get patientIdParam() {
    return this.params.patientId;
  }

  // Computed
  get patientId() {
    const patient = patientStore.result;
    if (patient && this.patientIdParam)
      return HalUtils.getId(patientStore.result);
    return null;
  }

  // Computed
  get userName() {
    return this.user
      ? `${checkName(this.user.firstName)} ${checkName(this.user.lastName)}`
      : 'Unknown';
  }

  // Computed
  get patientName() {
    return this.patient
      ? `${checkName(this.patient.firstName)} ${checkName(
          this.patient.lastName
        )}`
      : 'Unknown';
  }

  // Computed
  get focus() {
    const path = RouterContainer.path;

    // the patient employee side by side doesn't have activity in the path 'compare view' with patient on the left
    if (/^\/patient\//.test(path)) return 'patient_employee';
    // compare view with EMR User on the left
    if (/^\/employee\//.test(path)) return 'employee_patient';
    // patient careflow and patient detail
    if (/^\/activity\/patient\/.*/.test(path)) return 'patient';
    // EMR User Only Accesses
    if (/^\/activity\/employee\/.*\/accesses/.test(path))
      return 'employee_accesses';
    // Diversion incidents and analysis
    if (/^\/activity\/employee\/.*\/incidents/.test(path))
      return 'employee_incidents';
    // EMR user workflow and user detail
    if (/^\/activity\/employee\/.*/.test(path)) return 'employee';

    return null;
  }

  // Computed
  get drawerTabs() {
    if (this.focus === 'employee_accesses') return [TAB_EVENTS];
    if (this.focus === 'employee_incidents')
      return [TAB_INCIDENTS, TAB_ASSESSMENTS];
    if (
      PermissionStore.has('PRIVACY_ASSESSMENT_VIEW') &&
      this.accessesVisible
    ) {
      return [TAB_EVENTS, TAB_ASSESSMENTS];
    }
    return [TAB_EVENTS];
  }

  // Action
  setDrawerTab = newTab => {
    this.activeDrawerTab = newTab;
  };

  // Computed
  get shouldShowBothPersonBoxPages() {
    return (
      (this.focus === 'patient' &&
        // The patient focus means either patient-only or patient careflow.
        // We do not show both pages on the careflow.
        !this.userIdParam) ||
      (this.focus === 'employee' &&
        // The employee focus means either user-only or user workflow.
        // We do not show both pages on the workflow.
        !this.patientIdParam) ||
      this.focus === 'patient_employee' ||
      this.focus === 'employee_patient'
    );
  }

  get hideUserColumn() {
    return this.mode === 'is--patient_summary';
  }

  get hidePatientColumn() {
    return this.mode === 'is--employee_summary';
  }

  // Computed
  get isUserPatientActivityView() {
    return (
      this.mode === 'is--employee_activity_view' ||
      this.mode === 'is--patient_activity_view'
    );
  }

  // Computed
  get focusIsNoneOrTwoPeople() {
    return (
      this.focus === 'employee' ||
      this.focus === 'patient' ||
      this.focus === 'employee_accesses' ||
      this.focus === 'employee_incidents' ||
      !this.focus
    );
  }

  get mode() {
    const focus = this.focus;
    const uid = this.userIdParam;
    const pid = this.patientIdParam;
    // Using the router path in here for times (firefox and ie) where setting the patient
    // in the store is slow and so avoid flashing the summary page when going from a UOA to a regular access
    // it is also making sure the activity view updates the size in the right order.

    // Compare view with patient on the left
    if (focus === 'patient_employee') return 'is--summary is--patient_user';
    // Compare view with EMR user on the left
    if (focus === 'employee_patient') return 'is--summary is--user_patient';
    // patient careflow or patient summary
    if (focus === 'patient')
      return (
        ((uid || RouterContainer.path.includes('/employee/')) &&
          'is--patient_activity_view') ||
        'is--patient_summary'
      );
    // User workflow and user detail
    if (focus === 'employee')
      return (
        ((pid || RouterContainer.path.includes('/patient/')) &&
          'is--employee_activity_view') ||
        'is--employee_summary'
      );
    // User only activity view
    if (focus === 'employee_accesses' || focus === 'employee_incidents')
      return 'is--employee_activity_view';
    return null;
  }

  // Computed
  get isPatientFocus() {
    return this.focus === 'patient' || this.focus === 'patient_employee';
  }

  // Computed
  get isUserFocus() {
    return (
      this.focus === 'employee' ||
      this.focus === 'employee_patient' ||
      this.focus === 'employee_accesses' ||
      this.focus === 'employee_incidents'
    );
  }

  // Computed
  get userRelationships() {
    const relationships = RelationshipStore.userRelationships.get(
      this.userIdParam
    );
    if (relationships) {
      return relationships.filter(
        r =>
          !r.relationships.includes('alias') &&
          !r.relationships.includes('primary care provider')
      );
    }
    return null;
  }

  // Computed
  get patientRelationships() {
    const relationships = RelationshipStore.patientRelationships.get(
      this.patientIdParam
    );
    if (relationships) {
      return relationships.filter(r =>
        r.relationships.includes('primary care patient')
      );
    }
    return null;
  }

  /**
   * Returns a comma-separated string of users which includes the primary user and any active aliases
   */
  // Computed
  get activeUsers() {
    if (this.userIdParam) {
      const userIds = [this.userIdParam];

      // this could call this.userAliases instead, but doing so would generate
      // additional calls when the computed value of this.userAliases changes
      const relationships = RelationshipStore.userRelationships.get(
        this.userIdParam
      );
      if (relationships) {
        const aliases = relationships.filter(r =>
          r.relationships.includes('alias')
        );

        if (aliases) {
          aliases.forEach(alias => {
            // if statement instead of a filter as we need to preserve the original index 'i'
            if (HalUtils.getEndpoint(alias.ids[1]) === 'users') {
              // pre-fetch the aliases of an alias for optimization
              RelationshipStore.fetchUser(alias.ids[1].id);
              userIds.push(alias.ids[1].id);
            }
          });
        }
      }

      return userIds.sort().join();
    }

    return '';
  }

  /**
   * Returns a comma-separated string of patients which includes the primary patient and any active aliases
   */
  // Computed
  get activePatients() {
    if (this.patientIdParam) {
      const patientIds = [this.patientIdParam];

      // this could call this.patientAliases instead, but doing so would generate
      // additional calls when the computed value of this.patientAliases changes
      const relationships = RelationshipStore.patientRelationships.get(
        this.patientIdParam
      );
      if (relationships) {
        const aliases = relationships.filter(r =>
          r.relationships.includes('alias')
        );

        if (aliases) {
          aliases.forEach(alias => {
            // if statement instead of a filter as we need to preserve the original index 'i'
            if (HalUtils.getEndpoint(alias.ids[1]) === 'patients') {
              // pre-fetch the aliases of an alias for optimization
              RelationshipStore.fetchPatient(alias.ids[1].id);
              patientIds.push(alias.ids[1].id);
            }
          });
        }
      }

      return patientIds.sort().join();
    }

    return '';
  }

  // Computed
  get sortField() {
    const sort = RouterContainer.query.sort;
    if (sort) return sort.split(',')[0].split('.')[0];
    return null;
  }

  // Computed
  get filterParams() {
    const params = {};

    if (this.subFilter1 || this.subFilter2) {
      params.filterField = this.isUserFocus
        ? `patient.${this.patientLabel}`
        : `user.${this.userLabel}`;
      params.filterValues = [this.subFilter1, this.subFilter2]
        .map(v => (v === undefined ? '' : v))
        .join();
    }

    return params;
  }

  // Computed
  get isPatientSort() {
    return this.sortField === 'patient';
  }

  // Computed
  get isUserSort() {
    return this.sortField === 'user';
  }

  // Computed
  get badDates() {
    return this.toDate.diff(this.fromDate, 'days') < 0;
  }

  // Computed
  get shouldViewDiversionActivity() {
    return (
      (PermissionStore.hasAll(
        'MEDICATION_STATISTICS_VIEW',
        'MEDICATION_INCIDENT_VIEW'
      ) ||
        PermissionStore.hasAll(
          'MEDICATION_STATISTICS_VIEW',
          'INCIDENT_VIEW'
        )) &&
      this.isUserFocus
    );
  }

  // Computed
  get isDiversionRowSelected() {
    return this.focus === 'employee_incidents';
  }

  // Computed
  get filterOptions() {
    const filterOptions = [
      ['ACCESS_VIEW', 'accesses'],
      ['MEDICATION_ADMINISTRATION_VIEW', 'administrations'],
      ['MEDICATION_HANDLING_VIEW', 'handlings'],
      ['MEDICATION_DISCREPANCY_VIEW', 'discrepancies'],
      ['ORDER_VIEW', 'orders'],
      ['TIME_ENTRY_VIEW', 'timeEntries'],
    ];

    return filterOptions
      .filter(option => PermissionStore.has(option[0]))
      .map(option => option[1]);
  }

  // Computed
  get filterOptionsVisible() {
    return this.filterOptions.map(titleString => {
      return this[`${titleString}Visible`];
    });
  }

  // Computed
  get activeFiltersCount() {
    return [...this.filterOptionsVisible].reduce((acc, curr) => {
      return curr ? (acc += 1) : acc;
    }, 0);
  }

  // Computed
  get activityViewDocumentTitle() {
    const { focus } = this;
    if (focus === 'patient_employee' || focus === 'employee_patient') {
      return 'Compare';
    }
    if (focus === 'patient') {
      return 'Patient Careflow';
    }
    if (
      focus === 'employee' ||
      focus === 'employee_accesses' ||
      focus === 'employee_incidents'
    ) {
      return 'EMR User Workflow';
    }

    return null;
  }

  // Computed
  get currentLink() {
    return `${RouterContainer.path}${RouterContainer.search || ''}`;
  }

  refresh() {
    userStore.refresh();
    patientStore.refresh();
    vipStore.refresh();
  }

  recenterOn(access) {
    // Update the data visualization to re-center on the past or future
    // access date, while maintaining the zoom level that the customer
    // has set on the visualization
    const today = moment();

    const { fromDate, toDate } = this;
    let query = {};

    // Calculate the amount of time (in milliseconds) to remove or add to the dates in the window
    const offset = Math.ceil(toDate.diff(fromDate) / 2);

    // calculate the new to date
    const newToDate = moment(access.dateOfAccess).add(offset, 'milliseconds');

    // check to make sure the newToDate isn't after the current date
    // (today); set to today if it is, and adjust the fromDate to match
    if (newToDate.isAfter(today)) {
      query.toDate = today.format('MM-DD-YYYY');
      query.fromDate = moment()
        .subtract(offset * 2, 'milliseconds')
        .format('MM-DD-YYYY');
    } else {
      // set the new toDate and fromDate values based on the offset
      query.toDate = newToDate.format('MM-DD-YYYY');
      query.fromDate = moment(access.dateOfAccess)
        .subtract(offset, 'milliseconds')
        .format('MM-DD-YYYY');
    }

    query = Object.assign({}, RouterContainer.query, query);
    RouterContainer.go(RouterContainer.path, query);
  }

  getQuery = (opts = {}, removeSubFilters = false) => {
    let fromDate = opts.fromDate || this.fromDate;
    let toDate = opts.toDate || this.toDate;
    const sort = opts.sort || this.sort;

    const subFilter1 =
      opts.subFilter1 === undefined ? this.subFilter1 : opts.subFilter1;
    const subFilter2 =
      opts.subFilter2 === undefined ? this.subFilter2 : opts.subFilter2;

    fromDate = moment.isMoment(fromDate)
      ? fromDate.format('MM-DD-YYYY')
      : moment(fromDate).format('MM-DD-YYYY');
    toDate = moment.isMoment(toDate)
      ? toDate.format('MM-DD-YYYY')
      : moment(toDate).format('MM-DD-YYYY');

    const params = {
      fromDate,
      toDate,
      userLabel: opts.userLabel || this.userLabel,
      patientLabel: opts.patientLabel || this.patientLabel,
      subFilter1,
      subFilter2,
    };

    if (opts.focusEvent) params.focusEvent = opts.focusEvent;

    if (params.userLabel === 'name' || removeSubFilters)
      delete params.userLabel;
    if (params.patientLabel === 'name' || removeSubFilters)
      delete params.patientLabel;
    if (sort && sort !== 'startTime,asc') params.sort = sort;
    if (!params.subFilter1 || removeSubFilters) delete params.subFilter1;
    if (!params.subFilter2 || removeSubFilters) delete params.subFilter2;

    return toJS(params);
  };

  getParams = (query, removeSubFilters = false) =>
    `?${$.param(this.getQuery(query, removeSubFilters), true)}`;

  // Get the alias link for either a user or a patient record
  isPatient(alias) {
    return HalUtils.getEndpoint(alias) === 'patients';
  }

  // Get the alias link for either a user or a patient record
  findLink(alias) {
    return this.currentLink.replace(
      this.isPatient(alias) ? this.patientIdParam : this.userIdParam,
      alias.id
    );
  }

  getUserLink(opts = {}) {
    const userId = opts.userId || (opts.user && opts.user.id) || this.userId;
    const userView = `/activity/employee/${userId}`;
    return userView + this.getParams(opts);
  }

  getPatientLink(opts = {}) {
    const patientId = opts.patientId || this.patientId;
    const patientView = `/activity/patient/${patientId}`;
    return patientView + this.getParams(opts);
  }

  getUserPatientLinks(opts = {}) {
    const userId = opts.userId || (opts.user && opts.user.id) || this.userId;
    const patientId =
      opts.patientId || (opts.patient && opts.patient.id) || this.patientId;

    const userPatientView = `/employee/${userId}/patient/${patientId}`;
    const patientUserView = `/patient/${patientId}/employee/${userId}`;
    const userPatientActivityView = `/activity${userPatientView}`;
    const patientUserActivityView = `/activity${patientUserView}`;

    const params = this.getParams(opts);
    const paramsWithNoSubFilters = this.getParams(opts, true);
    const links = {};

    links.userPatient = userPatientView + params;
    links.patientUser = patientUserView + params;
    links.userPatientActivity = userPatientActivityView + params;
    links.patientUserActivity = patientUserActivityView + params;

    links.emrUserWorkflow = userPatientActivityView + paramsWithNoSubFilters;
    links.patientCareflow = patientUserActivityView + paramsWithNoSubFilters;

    return links;
  }

  personLink = (id, personType, fromDate, toDate) =>
    this[`get${personType.capitalizeFirstLetter()}Link`]({
      [`${personType}Id`]: id,
      fromDate,
      toDate,
    });

  linkIncident = focusEvent => {
    const opts = {};
    if (focusEvent) opts.focusEvent = focusEvent;
    const userId = this.params.employeeId;
    const route = `/activity/employee/${userId}/incidents`;
    return route + this.getParams(opts);
  };

  /**
   * Download the accesses as a csv file, using the user and patient for the
   * file name.
   *
   * @param {String} eventType  the type of event (e.g. accesses)
   * @returns {Object}          the promise for downloading the CSV
   */
  downloadCSV = eventType => {
    const formatDateFileLabel = date => {
      return this.timezone
        ? moment(date)
            .tz(this.timezone)
            .format('l')
        : moment(date).format('l');
    };

    let csvHref = '';
    let title = '';
    const fp = this.filterParams;

    const client = this.clientMap[eventType];

    const shiftedFromDate = DateHelpers.formatDateWithISOOffset(
      DateHelpers.getDayStart(this.fromDate),
      this.timezone
    );

    const shiftedToDate = DateHelpers.formatDateWithISOOffset(
      DateHelpers.getStartOfNextDay(this.toDate),
      this.timezone
    );

    if (eventType === 'Time Entries') {
      // Special case for user time entries; we don't care about what focus exists.
      csvHref = client.getURL({
        users: this.activeUsers.split(),
        startDate: shiftedFromDate,
        endDate: shiftedToDate,
      });
      title = `${this.userName} ${eventType}`;
    } else if (this.focus === 'employee_accesses') {
      // User only events
      csvHref = client.getURL(
        this.activeUsers.split(),
        '',
        shiftedFromDate,
        shiftedToDate,
        true,
        fp.filterField,
        fp.filterValues,
        undefined,
        this.maxAccesses,
        true
      );
      title = `${this.userName}${
        this.focus === 'employee_accesses' ? ' user only' : ''
      } ${eventType}`;
    } else if (this.patientId && this.userId) {
      // Events between user and patient
      csvHref = client.getURL(
        this.activeUsers.split(),
        this.activePatients.split(),
        shiftedFromDate,
        shiftedToDate,
        this.includeUOA,
        fp.filterField,
        fp.filterValues,
        undefined,
        this.maxAccesses,
        true
      );
      title = `${eventType} between ${this.userName} and ${this.patientName}`;
    } else if (this.userId) {
      // All events for user
      csvHref = client.getURL(
        this.activeUsers.split(),
        undefined,
        shiftedFromDate,
        shiftedToDate,
        true,
        fp.filterField,
        fp.filterValues,
        undefined,
        this.maxAccesses,
        true
      );
      title = `${this.userName} ${eventType}`;
    } else {
      // All events for patient
      csvHref = client.getURL(
        undefined,
        this.activePatients.split(),
        shiftedFromDate,
        shiftedToDate,
        false,
        fp.filterField,
        fp.filterValues,
        undefined,
        this.maxAccesses,
        true
      );
      title = `${this.patientName} ${eventType}`;
    }

    title += ` from ${formatDateFileLabel(
      this.fromDate
    )} to ${formatDateFileLabel(this.toDate)}.csv`;

    DownloadUtils.downloadFromServer(csvHref, title);
  };

  setOptionsVisible = val => (this.optionsVisible = val);
}

decorate(Store, {
  // Observable
  accessesVisible: observable,
  activeDrawerTab: observable,
  administrationsVisible: observable,
  discrepanciesVisible: observable,
  employeePg1Visible: observable,
  fromDate: observable,
  handlingsVisible: observable,
  optionsVisible: observable,
  timeEntriesVisible: observable,
  ordersVisible: observable,
  params: observable,
  patientLabel: observable,
  patientPg1Visible: observable,
  sort: observable,
  subFilter1: observable,
  subFilter2: observable,
  toDate: observable,
  uoaMergedPreference: observable,
  userLabel: observable,
  // Computeds
  activeFiltersCount: computed,
  activeLabel: computed,
  activePatients: computed,
  activeUsers: computed,
  badDates: computed,
  currentLink: computed,
  drawerTabs: computed,
  activityViewDocumentTitle: computed,
  filterOptions: computed,
  filterOptionsVisible: computed,
  filterParams: computed,
  focus: computed,
  isUserPatientActivityView: computed,
  hideUserColumn: computed,
  hidePatientColumn: computed,
  shouldShowBothPersonBoxPages: computed,
  focusIsNoneOrTwoPeople: computed,
  includeUOA: computed,
  isDiversionRowSelected: computed,
  isPatientFocus: computed,
  isPatientSort: computed,
  isUserFocus: computed,
  isUserSort: computed,
  mode: computed,
  patient: computed,
  patientId: computed,
  patientIdParam: computed,
  patientName: computed,
  patientRelationships: computed,
  timezone: computed,
  shouldViewDiversionActivity: computed,
  sortField: computed,
  user: computed,
  userTags: computed,
  userIdParam: computed,
  userId: computed,
  userName: computed,
  userRelationships: computed,
  vipRegistration: computed,
  loading: computed,
  // Action
  toggleMergedUOA: action,
  setDrawerTab: action,
  setOptionsVisible: action,
});

export default new Store();
